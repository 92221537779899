.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
    padding: 14px;
}

.showtime-preview>div:first-child {
    font-size: 12px;
    white-space: normal;
}

.showtime-preview>div:not(:first-child) {
    font-size: 11px;
    white-space: normal;
}

.movie-tooltip .movie-info {
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    text-align: left;
}

.movie-tooltip img {
    height: 80px;
    margin-bottom: 10px;
}

.movie-tooltip .movie-title {
    font-size: 1.5em;
    line-height: 40px;
}

.long-title h3 {
    font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
    font-weight: 200;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.central__merchandise__image--container {
    overflow: hidden;
    border-radius: 5px;
    max-width: fit-content;
}

.central__merchandise__image--thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.central__merchandise__image--preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 250ms ease;
}

.central__merchandise__image--preview:hover {
    opacity: 0.8;
}

.feeding__user__image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0;
    padding: 0;
}

.dx-scheduler-time-panel {
    font-size: 11px;
}

.dx-scheduler-date-table-cell {
    /* width:150px; */
    height: 15px;
    opacity: 1;
    text-align: right;
    font-size: 2px;
    color: rgba(255, 255, 255, 0);
}

.dx-scheduler-time-panel-cell,
.dx-scheduler-cell-sizes-vertical {
    height: 18px;
    font-size: 12px;
}

.dx-scheduler-time-panel-row {
    align-items: center;
    justify-items: center;
    /* vertical-align: middle; */
    text-align: center;
}

.dx-scheduler-group-header,
.dx-scheduler-header-panel,
.dx-scheduler-time-panel {
    font-size: 12px;
}

.dx-scheduler-group-header-content div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.dx-scheduler-overlay-panel .dx-popup-content .dx-list-item .dx-list-item-content {
    padding: 0px;
}

.dx-item-content {
    padding: 0;
    font-size: 12px;
}

.dx-scheduler-appointment-content {
    font-size: 12px;
}

.dx-scheduler-work-space-grouped:not(.dx-scheduler-work-space-all-day):not(.dx-scheduler-timeline):not(.dx-scheduler-agenda):not(.dx-scheduler-work-space-vertical-grouped):not(.dx-scheduler-work-space-horizontal-grouped).dx-scheduler-work-space-day[dx-group-row-count='1'] .dx-scheduler-header-scrollable {
    height: 41px;
}

:not(.dx-scheduler-work-space-vertical-grouped)[dx-group-row-count='1'] .dx-scheduler-all-day-title:before {
    top: -41px;
    height: 40px;
}

.dx-scheduler-appointment {
    width: 100%;
    background-color: rgba(102, 102, 102, 0);
}

/* background-color:#EBF549; */
/* .dx-scrollview-content{
    pointer-events:none;
} */

/* .ant-table-row, .ant-table-row-level-0{
    height: 20px;
} */
/* .ant-table-tbody{
    height: 300px;
} */
.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
    height: 20px;
    padding: 4px;
}

/* .ant-table-tbody-slim > tr > td,
.ant-table-thead-slim > tr > th {
    height: 15px;
    padding: 0px;
} */
/* .calendar-container-slim {
    background-color: white;
    max-width: 210px;
} */
.dx-calendar-cell {
    padding: 0;
    width: 15px;
    font-size: 12px;
}

.dx-calendar {
    width: 210px;
    min-width: 210px;
}

.dx-calendar-body {
    width: 210px;
}

.dx-calendar-views-wrapper {
    width: 210px;
}

.dx-widget>table>thead>tr>th {
    font-size: 12px;
    padding: 0;
}

.dx-widget>table>tbody>tr>td {
    font-size: 12px;
    padding: 0;
}

.table-slim {
    font-size: 12px;
}

.ant-table-tbody>tr>td>dx-calendar-cell .dx-scheduler>dx-widget>dx-visibility-change-handler {
    display: none
}

.dx-scheduler-header {
    display: none
}

.dx-scheduler-navigator .dx-widget {
    display: none
}

.dx-button-mode-contained.dx-button-success {
    background-color: #86d0cc;
}

.dx-button-mode-contained.dx-button-success.dx-state-hover {
    background-color: #86d0cc;
    border-color: transparent;
}

.dx-scheduler-cell-sizes-horizontal {
    width: 100px;
    font-size: 12px;
}

.ant-dropdown-menu {
    max-height: 250px;
    overflow: auto;
}